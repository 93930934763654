<script setup>

import { defineProps, onMounted, onUpdated, ref, computed } from 'vue';
import { Gems } from '../../gems';

const divTeams = ref(null)
const divJob = ref(null)
const divName = ref(null)

const defaultImage = require('../../images/default.png');

const props = defineProps({
    user: Object
})

const tooltipManyTeamsText = computed(() => {
    return props.user.teams.map(team => team.team_name).join('\n')
})

function HandleDragStart(ev) {
    ev.dataTransfer.setData('application/json', JSON.stringify(props.user))
}

onMounted(() => {
    if(props.user.teams.length > 0)
        Gems.Tooltip(divTeams.value, tooltipManyTeamsText.value, 'tooltip')
    Gems.Tooltip(divName.value, props.user.full_name, 'tooltip')
    Gems.TooltipIfNeeded(divJob.value, props.user.job_name, 'tooltip')
})

onUpdated(() => {
    if(props.user.teams.length > 0)
        Gems.Tooltip(divTeams.value, tooltipManyTeamsText.value, 'tooltip')

    Gems.Tooltip(divName.value, props.user.full_name, 'tooltip')
})

</script>

<template>
    <div class="div-user-card draggable" draggable="true" @dragstart="HandleDragStart($event)">
        <div class="div-border-card">
            <div class="div-inner-card">
                <div class="photo">
                    <img :src="props.user.src ?? defaultImage" @error="$event.target.src = defaultImage" class="cover" />
                </div>
    
                <div class="div-user-info">
                    <div ref="divName" class="line-clamp line-clamp-1 div-user-name">
                        {{ props.user.name }}
                    </div>
    
                    <div ref="divJob" class="line-clamp line-clamp-1 div-job-name">
                        {{ props.user.job_name ?? '[sem função]' }}
                    </div>
    
                    <div ref="divTeams" class="div-users-teams">
    
                        <div v-if="props.user.teams.length == 0">
                            [sem equipa]
                        </div>
                        <div class="line-clamp line-clamp-1" v-if="props.user.teams.length === 1">
                            {{ props.user.teams[0].team_name }}
                        </div>
                        <div ref="divManyTeams" v-show="props.user.teams.length > 1">
                            {{ props.user.teams.length }} equipas
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.div-user-card {
    display: inline-block;
    width: 210px;
    height: 220px;

    margin-right: 20px;
    margin-top: 40px;
}

.div-border-card {
    border-radius: 11px;
    padding: 2px;
    background: white;
    height: 100%;

}

.div-border-card:hover {
    background: var(--input-gradient-border);
}

.div-border-card:hover .div-inner-card {
    background-color: #000000f2;
}

.photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    color: white;
    position: relative;
    top: -30px;
    background: var(--input-gradient-border);
    padding: 3px;
}

.cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: black;
}

.div-inner-card {
    text-align: center;
    margin: 0 auto;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    background-color: black;
    border-radius: 10px;
}

.div-user-name {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.div-job-name {
    color: rgb(135 255 165);
}
</style>