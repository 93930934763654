<script setup>
import { onMounted } from 'vue'
import Menu from '../components/Menu'
import { Gems } from '../gems'
import { HUB } from '../hub'

async function Initialize() {
    const togglePhotoPermission = document.getElementById('photo-permission')

    const responsePermissions = await HUB.CallBackEnd({ action: HUB.Action("get-app-permissions") })

    SetToggleState(togglePhotoPermission, responsePermissions.data.upload_photo)
}

async function Toggle(elem) {
    ToggleControl(elem)
        const permissionState = Number(elem.classList.contains('fa-toggle-on'))
        const response = await HUB.CallBackEnd({ action: HUB.Action("upload-photo-permission"), data: { permissionState } })
        if (response.error)
            Gems.Telegram(response.message, 'tele-not-allowed')
}

function ToggleControl(elem) {
    if (elem.classList.contains('fa-toggle-off')) {
        SetToggleState(elem, true)
    } else {
        SetToggleState(elem, false)
    }
}

function SetToggleState(element, state) {
    if (Number(state)) {
        element.classList.remove('fa-toggle-off')
        element.classList.add('fa-toggle-on')
        return
    }
    element.classList.remove('fa-toggle-on')
    element.classList.add('fa-toggle-off')
}

onMounted(() => {
    Initialize()
})

</script>


<template>
    <div id="div-container" class="main-container-background">
        <Menu></Menu>
        <div class="page-container">
            <img class="img-inpeople-default" src="../images/inpeople.png">

            <div>
                <div class="div-label-title">
                    DEFINIÇÕES
                </div>

                <div class="div-row">
                    <i id="photo-permission" class="toggle-icon fa fa-toggle-off" @click="Toggle($event.target)"></i>
                    <div class="div-label label">Utilizadores podem carregar a foto de perfil</div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
#div-container {
    display: flex;
    min-height: 100vh;
}

.page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 35px 25px 14px;
}

.img-inpeople-default { 
    margin-bottom: 27px;
}

.div-label-title {
    text-align: center;
    font-size: 20pt;
    opacity: .3;
    margin-bottom: 23px;
}

.div-row {
    display: flex;
    align-items: center;
}

.toggle-icon {
    font-size: 1.3em;
    cursor: pointer;
    margin-right: 15px;
}

</style>