<script setup>
import { onMounted, ref } from 'vue';
import Menu from '../components/Menu'
import { HUB } from '../hub';
import UserCard from '../components/teams/UserCard.vue';
import TeamCard from '../components/teams/TeamCard.vue';
import { Gems } from '../gems';

const showInactiveUsers = ref(false)

const users = ref([])
const teams = ref([])

onMounted(async () => {
    const response = await HUB.CallBackEnd({
        action: HUB.Action('get-teams-users')
    })

    users.value = response.data.users
    teams.value = response.data.teams
})

async function InsertTeam() {

    const teamInternal = document.getElementById('txt-team-internal')
    const teamName = document.getElementById('txt-team-name')

    if (teamInternal.value.trim() === '' || teamName.value.trim() === '') {
        Gems.Telegram('Preencha todos os campos', 'tele-not-allowed')
        return
    }

    let response = await HUB.CallBackEnd({
        action: HUB.Action('insert-team'),
        data: {
            designation: teamName.value,
            internal: teamInternal.value
        }
    })

    if (!response.error) {
        Gems.Telegram('Equipa criada com sucesso', 'tele-success')
        teamInternal.value = ''
        teamName.value = ''
    }

    response = await HUB.CallBackEnd({
        action: HUB.Action('get-teams-users&only_teams')
    })

    teams.value = response.data.teams
}

</script>

<template>
    <div id="div-container" class="main-container-background">
        
        <Menu></Menu>
        <div class="container">
        
        <img class="img-inpeople-default" src="../images/inpeople.png">

        <div id="div-teams-management">
            <div id="div-users" class="scrollbar">

                <div id="div-show-inactive-users">
                    <input @click="showInactiveUsers = !showInactiveUsers" type="checkbox">
                    <span>Mostrar inativos</span>
                </div>

                <UserCard v-for="(user, index) in users" :key="index" :user="user" :class="{
                    'hidden': !user.app_count && !showInactiveUsers
                }">
                </UserCard>
            </div>
            <div id="div-teams" class="scrollbar">

                <div id="div-teams-create">
                    <div id="div-team-internal">
                        <input id="txt-team-internal" type="text" class="txt-input" placeholder="Código da equipa"
                            required />
                    </div>
                    <div id="div-team-name">
                        <input id="txt-team-name" type="text" class="txt-input" placeholder="Nome da equipa" required />
                    </div>

                    <div id="div-btn-create" class="btns inputs-border">
                        <button type="button" class="button" @click="InsertTeam">CRIAR EQUIPA</button>
                    </div>
                </div>

                <TeamCard v-for="(team, index) in teams" :key="index" :team="team"
                    @insert-team-user="(callback) => callback(team, users)"
                    @delete-team-user="(callback) => callback(team, users)">
                </TeamCard>
            </div>
        </div>
        </div>
    </div>
</template>

<style scoped>
#div-container {
    display: flex;
    min-height: 100vh;
}

.container {
    padding: 25px 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.img-inpeople-default {
    margin-bottom: 20px;
}

#div-teams-management {
    display: flex;
    max-height: 86vh;
}

#div-users {
    padding: 0px 14px 0px 0px;
    flex: 0.8;
}

#div-teams {
    flex: 0.4;
    padding: 0px 0px 0px 14px;
    margin-right: -5px;
}

#div-users,
#div-teams {
    overflow-y: scroll;

}

#div-show-inactive-users,
#div-teams-options {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

#div-show-inactive-users>[type="checkbox"],
#div-teams-options>[type="checkbox"] {
    transform: scale(1.5);
    cursor: pointer;
}

#div-teams-create {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
}
#div-teams-create > div {
    height: 100%;
}


.txt-input {
    width: 100%;
    border-radius: 7px;
    height: 100%;
}

.button {
    height: 100%;
    padding: 0;
}

#div-team-internal {
    flex: .7;
}

#div-btn-create {
    flex: .6;
}

#div-team-name {
    flex: 2;
}
</style>
