
<script setup>
import { Gems } from "@/gems"
import { HUB } from "@/hub"
import { ref, defineProps, onMounted } from "vue"

const props = defineProps(['userId', 'username', 'entity'])

const passwordEye = ref(true)
const confirmPasswordEye = ref(true)

const passRules = ref({})

const txtPassword = ref('')
const txtConfirmPassword = ref('')

function passwordStrength() {
  passRules.value = Gems.GetPassRulesConfirmation(txtPassword.value.value, txtConfirmPassword.value.value, props.username)
}

function ChangeVisibility(id) {
  if (id === "password")
    passwordEye.value = !passwordEye.value
  else if (id === "confirmPassword")
    confirmPasswordEye.value = !confirmPasswordEye.value

  const password = document.getElementById(id)
  const type = password.getAttribute("type") === "password" ? "text" : "password"
  password.setAttribute("type", type)
}

async function Recovery() {
  if (!txtPassword.value.value) {
    Gems.Telegram("É necessário informar a nova password", "tele-not-allowed")
    return
  }

  if (!txtConfirmPassword.value.value) {
    Gems.Telegram("É necessário confimar a password", "tele-not-allowed")
    return
  }

  if (!Gems.IsPasswordValid(passRules.value)) {
    Gems.Telegram("Regras para Password não cumpridas", "tele-not-allowed")
    return
  }

  const btnSave = document.getElementById("save-pass")

  btnSave.innerText = "Aguarde...";
  btnSave.setAttribute("disabled", true);

  const response = await HUB.CallBackEnd({
    action: HUB.Action("update-password"),
    data: {
      pass: txtPassword.value.value,
      passConfirm: txtConfirmPassword.value.value,
      userId: props.userId,
      entity: props.entity
    }
  })

  btnSave.innerText = "Gravar password"
  btnSave.setAttribute("disabled", false)

  if (response.error == true) {
    Gems.Telegram(response.msg, "tele-not-allowed")
    return
  }

  Gems.Telegram("Password alterada", "tele-success")
  window.parent.location.href = "/"

}

onMounted(() => {
  Gems.GeneratePassRules(document.getElementById('pass-rule-container'))
})
</script>

<template>
  <div id="div-main-background">

    <div class="div-left-side">
      <div class="clickable" id="div-inpeople-logo" @click="$router.push('/')"></div>
    </div>

    <form class="form" id="loginForm" @submit.prevent="Recovery()">
      <div class="div-welcome">
        <div class="lbl-app">Recuperação de password</div>
      </div>

      <div id="div-password">
        <input type="password" id="password" ref="txtPassword" class="user-input" placeholder="Password" required
          autocomplete="on" 
          @input="passwordStrength">

        <div class="eye-password">
          <i @click="ChangeVisibility('password')" class="fas"
            :class="{ 'fa-eye': passwordEye, 'fa-eye-slash': !passwordEye }"></i>
        </div>
      </div>

      <div>
        <input type="password" id="confirmPassword" ref="txtConfirmPassword" class="user-input" placeholder="Repetir password"
          required autocomplete="on"
          @input="passwordStrength">

        <div class="eye-password">
          <i @click="ChangeVisibility('confirmPassword')" class="fas"
            :class="{ 'fa-eye': confirmPasswordEye, 'fa-eye-slash': !confirmPasswordEye }"></i>
        </div>
      </div>

      <div id="pass-rule-container" class="pass-rule-container">
      </div>

      <button id="save-pass" type="submit" class="btn-main">
        Gravar password
      </button>
    </form>
  </div>
</template>
  
<style scoped>

.pass-rule-container {
  margin: 0
}

.div-master-footer {
  display: none !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.form {
  width: 30%;
  align-self: center;
}

.form * {
  border: none;
  outline: none;
}


#div-main-background {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  height: 100vh;
  margin: 0 auto;
  background-size: cover;
  backdrop-filter: saturate(1.5) !important;
  background-image: linear-gradient(211deg, rgb(0 255 228) 0%, rgb(0 255 221) 0%, rgb(182 124 255) 80%, rgb(153 94 255) 160%);
}

#div-inpeople-logo {
  position: relative;
  top: calc(50% - 100px);
  background-size: contain;
  background-repeat: no-repeat;
  width: 400px;
  height: 200px;
  background-image: url('../images/logo_inpeople_hub_login.png');
}

.div-left-side {
  display: flex;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  margin: auto 0;
  gap: 103px;
}

.div-welcome {
  color: white;
  margin-bottom: 80px;
  text-align: center;
}

.lbl-app {
  font-size: 2rem;
  font-weight: bold;
  color: black;
}

label {
  display: block;
  margin-top: 0px;
  font-size: 14px;
}

label:first-child {
  margin-top: 10px;
}

input {
  width: 100%;
  margin: 8px 0;
  display: block;
  padding: 0 10px;
  background-color: #ffffff00;
  font-weight: 600;
  border-bottom: 2px solid black !important;
  font-size: 1em;
}

::placeholder {
  color: black;
}


.btn-main {
  width: 100%;
  padding: 25px;
  border-radius: 10px;
  color: white;
  background-color: black;
  font-size: 1.1em;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 20px;
}

.btn-main:hover {
  background-color: rgb(205 176 255);
}

.recovery {
  margin-top: 19px;
}

.div-other label {
  color: #eaf0fb;
}

.div-recovery {
  display: flex;
  padding: 10px 0;
  margin-bottom: 20px;
  align-items: center;
  font-weight: 600;
}

.div-other div:last-child {
  color: #eaf0fb;
  margin-top: 10px;
}

.div-message-alert {
  height: 40px;
  font-size: .9em;
  text-align: center;
  color: orangered;
}


.ckb-forgot {
  width: 20px;
  margin-right: 5px;
}

.user-input {
  padding: 25px 15px !important;
  color: black !important;
  margin-top: 30px;
}

.eye-password {
  position: relative;
  width: 20px;
  top: -50px;
  left: 95%;
}

.eye-password i {
  font-size: .8em;
  cursor: pointer;
  color: black;
}

input::-ms-reveal {
  display: none;
}
</style>
