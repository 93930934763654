<script setup>
import { HUB } from '@/hub'
import { Gems } from "@/gems"
import { ref } from "@vue/reactivity"

import ImportUploadFile from "../components/ImportUploadFile"
import Menu from '../components/Menu'

const teamsFormationTable = ref(null);

async function Import(type) {

  const fileInput = document.getElementById(type)
  const file = document.getElementById(type).files[0]

  if (!file) {
    fileInput.click()
    return
  }

  let formData = new FormData()
  formData.append("csv", file)

  const response = await HUB.CallBackEnd({
    action: HUB.Action(`import&type=${type}`),
    data: formData
  });

  if (response.data.error) {
    Gems.Telegram(response.data.message, "tele-not-allowed")
    if (response.data.excel)
      Gems.ExportExcel(response.data.data)
    return
  }

  if (type == 'users') {
    const ws_res = await Gems.CallWS({ app: "kpi", action: "update-users-data" });
    if (ws_res?.data != true) {
      Gems.Telegram("Não foi possível gravar o histórico das equipas no CIRVALUE KPI", "tele-not-allowed");
      return
    }
  }

  Gems.Telegram(response.data.message, "tele-success")

  document.getElementById(type).value = null
}

async function Export(type) {
  const res = await HUB.CallBackEnd({
    action: HUB.Action(`export&type=${type}`)
  })
  
  if (res.error == 0) {
    Gems.ExportExcel(res.data);
  }
}

function ShowImportInstructions(id) {
  const divImportInstructions = document.getElementById(id)
  let text

  if(id == 1)
    text = `<li>Dado nulo (vazio) em campos binários [INATIVO] será interpretado como "0" (zero).</li>
              <li>Os dados NÃO deverão conter o caracter ";" (ponto e vírgula).</li></i>
              <br/>
              <li>Novo registo:</li>
              <ul>
                <li>[coluna A] precisa estar vazia.</li>
                <li>O valor da [coluna B] não existe na Base de Dados.</li>
              </ul>
              <li>Atualização [colunas B, C, D e E]:</li>
              <ul>
                <li>[coluna A]  vazia e valor da [coluna B] existe na Base de Dados > Atualiza C, D e E.</li>
                <li>[coluna A]  não vazia > Atualiza B, C, D e E.</li>
              </ul>
              <br>`

  if(id == 2)
    text = `<li>Os dados NÃO deverão conter o caracter ";" (ponto e vírgula).</li>
          <br/>
          <li>ATIVO (GLOBAL) [coluna J]:</li>
          <ul>
            <li>Valor nulo (vazio) será ignorado.</li>
            <li>Valor "0" ou "1" atualizará o campo [ATIVO] em todas as aplicações.</li>
            <li>Valor nulo (vazio) nos restantes campos binários, será interpretado como "0" (zero).</li>
          </ul>
          <br/>
          <li>Novo registo:</li>
          <ul>
            <li>[coluna A] precisa estar vazia.</li>
            <li>O valor da [coluna B] não existe na Base de Dados.</li>
          </ul>
          <li>Atualização [colunas B em diante]:</li>
          <ul>
            <li>[coluna A]  vazia e valor da [coluna B] existe na Base de Dados > Atualiza C em diante.</li>
            <li>[coluna A]  não vazia > Atualiza B em diante</li>
          </ul>
          <br>`

  if(id == 3)
    text = `<li>Os dados NÃO deverão conter o caracter ";" (ponto e vírgula).</li>
            <li>A tabela inteira será substituída. Todos os registos que não existirem no ficheiro carregado serão perdidos.</li>`

  if (divImportInstructions.children.length > 1) {
    window.CloseImportInstructions(id);
  } else {
    const div = document.createElement("div");
    divImportInstructions.appendChild(div);

    div.innerHTML += `<button class='fas fa-times btn-close-import-instructions-modal' onclick='CloseImportInstructions(${id})'></button>`;
    div.innerHTML += '<li>Apenas importe ficheiros que foram exportados deste interface.</li>'
    div.innerHTML += '<li>Não apague colunas do EXCEL exportado.</li>'
    div.innerHTML += '<li>Os registos serão processados pela ordem em que aparecem no ficheiro.</li>'
    div.innerHTML += text;
    div.innerHTML += '<li>Grave o ficheiro com o seguinte formato:</li><br/>'
    div.innerHTML += "<div class='div-excel-img'>";
    div.setAttribute("class", "div-import-instructions");
  }
}

window.CloseImportInstructions = (id) => {
  document
    .getElementById(id)
    .removeChild(document.getElementById(id).lastElementChild);

  teamsFormationTable.value.classList.remove('div-last-import-instructions-opened')
}


</script>


<template>
  <div id="div-container" class="main-container-background">

    <Menu></Menu>


    <div id="div-page-container">

      <img class="img-inpeople-default" src="../images/inpeople.png">

      <div class="div-container-info-circle">
        <div class="div-info-circle bg-field-unique"></div>
        <p class="p-info-text">
          Campos de dados únicos
        </p>
      </div>

      <div class="div-container-info-circle">
        <div class="div-info-circle bg-field-not-modify"></div>
        <p class="p-info-text">
          Em nenhuma circunstância modifique a coluna 'Key' [coluna A]
        </p>
      </div>

      <div>
        <div id="1" class="div-import-instructions-closed">
          <div class="div-icon-container">
            <i class="fas fa-info-circle icon" @click="
              ShowImportInstructions(1)
              ">
            </i>
            <span class="span-rule-text">Regras para importação</span>
          </div>
        </div>

        <div class="div-half-size-table">
          <div class="inputs-border div-btn-export">
            <button @click="Export('gender')" class="button clickable">
              <span>EXPORTAR GÉNEROS</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th>B. Código do género</th>
                <th>C. Descritor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Feminino</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'gender'" @import="Import($event)" />
          </div>
        </div>

        <div class="div-half-size-table">
          <div class="inputs-border div-btn-export">
            <button @click="Export('teams')" class="button clickable">
              <span>EXPORTAR EQUIPAS</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th>B. Código da equipa</th>
                <th>C. Descritor</th>
                <th>D. Inativo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Coordenação</td>
                <td>Ex.: 1</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo binário - 0 ou 1)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'teams'" @import="Import($event)" />
          </div>
        </div>
        <div class="div-half-size-table">
          <div class="inputs-border div-btn-export">
            <button @click="Export('categories')" class="button clickable">
              <span>EXPORTAR CATEGORIAS</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th>B. Código da categoria</th>
                <th>C. Descritor</th>
                <th>D. Inativo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Diretoria</td>
                <td>Ex.: 0</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo binário - 0 ou 1)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'categories'" @import="Import($event)" />
          </div>
        </div>
        <div class="div-half-size-table">
          <div class="inputs-border div-btn-export">
            <button @click="Export('jobs')" class="button clickable">
              <span>EXPORTAR FUNÇÕES</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th>B. Código da função</th>
                <th>C. Descritor</th>
                <th>D. Valor/Hora</th>
                <th>E. Inativo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: CEO</td>
                <td>Ex.: 20.50</td>
                <td>Ex.: 1</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo numérico)</td>
                <td>(campo binário - 0 ou 1)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'jobs'" @import="Import($event)" />
          </div>
        </div>
      </div>

      <div>
        <div id="2" class="div-import-instructions-closed">
          <div class="div-icon-container">
            <i class="fas fa-info-circle icon" @click="
              ShowImportInstructions(2)
              ">
            </i>
            <span class="span-rule-text">Regras para importação</span>
          </div>
        </div>

        <div>
          <div class="inputs-border div-btn-export">
            <button @click="Export('users')" class="button clickable">
              <span>EXPORTAR UTILIZADORES</span><span class="i-arrow">&#8595;</span>
            </button>
          </div>

          <table class="tbl-example tbl-example-users">
            <thead>
              <tr>
                <th class="bg-field-not-modify">A. Key</th>
                <th class="bg-field-unique">B. N° Interno</th>
                <th>C. Nome completo</th>
                <th>D. Nome profissional</th>
                <th>E. E-Mail</th>
                <th class="bg-field-unique">F. Username</th>
                <th>G. Género<br>[Código]</th>
                <th>H. Categoria<br>[Código]</th>
                <th>I. Função<br>[Código]</th>
                <th>J. Ativo<br>(Global)</th>
                <th class="td-fade-out-first">K. Ativo<br>(App 1)</th>
                <th class="td-fade-out-second">L. Admin<br>(App 1)</th>
                <th class="td-fade-out-third">M. Ativo<br>(App 2)</th>
                <th class="td-fade-out-fourth">N. Admin<br>(App 2)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Manuel da Silva Costa</td>
                <td>Ex.: Manuel Silva</td>
                <td>Ex.: manuel.silva@mail.pt</td>
                <td>Ex.: Silva.manuel</td>
                <td>Ex.: 10A0</td>
                <td>Ex.: 200B</td>
                <td>Ex.: 3D00</td>
                <td class="td-fade-out-first">Ex.: 1</td>
                <td class="td-fade-out-second">Ex.: 1</td>
                <td class="td-fade-out-third">Ex.: 0</td>
                <td class="td-fade-out-fourth">Ex.: 0</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo binário - 0 ou 1)</td>
                <td class="td-fade-out-first">(campo binário - 0 ou 1)</td>
                <td class="td-fade-out-second">(campo binário - 0 ou 1)</td>
                <td class="td-fade-out-third">(campo binário - 0 ou 1)</td>
                <td class="td-fade-out-fourth">(campo binário - 0 ou 1)</td>
              </tr>
            </tbody>
          </table>
          <div class="div-upload-file">
            <ImportUploadFile :tableName="'users'" @import="Import($event)" />
          </div>
        </div>
      </div>

      <div class="div-half-size-table">
        <div id="3" class="div-import-instructions-closed">
          <div class="div-icon-container">
            <i class="fas fa-info-circle icon" @click="
              teamsFormationTable.classList.toggle('div-last-import-instructions-opened')
            ShowImportInstructions(3);
            "></i>
            <span class="span-rule-text">Regras para importação</span>
          </div>
        </div>

        <div class="inputs-border div-btn-export">
          <button @click="Export('hierarchy')" class="button clickable">
            <span>EXPORTAR HIERARQUIA</span><span class="i-arrow">&#8595;</span>
          </button>
        </div>

        <table class="tbl-example">
          <thead>
            <tr>
              <th>A. N° interno da chefia</th>
              <th>B. N° interno do subordinado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ex.: A001</td>
              <td>Ex.: A020</td>
            </tr>
            <tr>
              <td>(campo alfa-numérico)</td>
              <td>(campo alfa-numérico)</td>
            </tr>
          </tbody>
        </table>
        <div class="div-upload-file">
          <ImportUploadFile :tableName="'hierarchy'" @import="Import($event)" />
        </div>
      </div>
      <div class="div-half-size-table" ref="teamsFormationTable">
        <div class="inputs-border div-btn-export">
          <button @click="Export('teams-formation')" class="button clickable">
            <span>EXPORTAR CONSTITUIÇÃO DAS EQUIPAS</span><span class="i-arrow">&#8595;</span>
          </button>
        </div>

        <table class="tbl-example">
          <thead>
            <tr>
              <th>A. Código da equipa</th>
              <th>B. Nº Interno</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ex.: A001</td>
              <td>Ex.: 0928213</td>
            </tr>
            <tr>
              <td>(campo alfa-numérico)</td>
              <td>(campo alfa-numérico)</td>
            </tr>
          </tbody>
        </table>
        <div class="div-upload-file">
          <ImportUploadFile :tableName="'teams_formation'" @import="Import($event)" />
        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>
#div-container {
  display: flex;
  font-family: "Space Grotesk", sans-serif;
}

#div-page-container {
  padding: 25px 2%;
  flex-grow: 1;
}

.div-container-info-circle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.div-info-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.p-info-text {
  font-size: .9em;
  margin: 0px 5px;
  font-weight: 300;
}

.div-upload-file {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
  align-items: center;
}

.tbl-example {
  width: 100%;
  height: 100px;
  text-align: center;
  word-wrap: break-word;
  border-collapse: separate;
  border: none;
  border-spacing: 0;
  background: #231f20;
  border-radius: 7px;
}

.tbl-example thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}


.tbl-example-users {
  border: none;
  position: relative;
}

.tbl-example-users::before {
  border-radius: 7px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(270deg, #231f20, rgba(0, 0, 0, 0) 3%);
  -webkit-mask-image: linear-gradient(270deg, #231f20, rgba(0, 0, 0, 0) 3%);
  border: none;
  background-color: black;
  z-index: 1;
}

.tbl-example thead tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.tbl-example thead:first-child tr:first-child th:first-child,
.tbl-example tbody:first-child tr:first-child td:first-child {
  border-radius: 7px 0 0 0;
}

.tbl-example thead:last-child tr:last-child th:first-child,
.tbl-example tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 7px;
}

.tbl-example th,
.tbl-example tbody td {
  text-align: left;
  vertical-align: top;
}

.tbl-example tbody td {
  border-top: 1px solid black;
}

.tbl-example tbody td:not(:first-child) {
  border-left: 1px solid black;
}

.div-half-size-table {
  width: 50% !important;
  margin-bottom: 20px;
}

.tbl-example td,
.tbl-example th {
  height: 30px;
}

.tbl-example thead th,
.tbl-example tbody td {
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
}

.tbl-example thead th {
  font-weight: bold;
  overflow: hidden;
  text-transform: uppercase;
}

.tbl-example thead th:not(:first-child) {
  border-left: 1px solid black;
}

.div-btn-export {
  margin-bottom: 5px;
}

.inputs-border div-btn-export:not(:first-of-type) {
  margin-top: 30px;
}

.inputs-border div-btn-export:first-of-type {
  margin-top: 10px;
}

.div-import-instructions-closed {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 5px;
}

.div-icon-container {
  display: flex;
  align-items: center;
}

.div-icon-container>span {
  font-weight: bold;
}

.icon {
  font-size: 1em;
  cursor: pointer;
  margin-right: 5px;
}

.bg-field-not-modify {
  background-color: #ff0050;
}

.bg-field-unique {
  background-color: rgba(24, 232, 255, 0.7);
}
</style>

<style>
.blink-import {
  animation: blink-import 1s step-start infinite;
}

@keyframes blink-import {
  50% {
    background: #00000040;
    color: black !important;
  }

  100% {
    background: black;
    color: var(--medium-gray-color) !important;
  }
}


.div-import-instructions {
  background-color: #8000bbbb;
  text-align: initial;
  box-shadow: 0 0 10px 10px #ffffff11;
  max-height: 720px !important;
  min-height: 20px;
  width: 631px;
  min-width: 290px;
  cursor: default;
  font-size: .8em;
  font-weight: 300;

  backdrop-filter: blur(10px);
  position: absolute;
  left: 30px;
  top: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 10;
}

.div-import-instructions li {
  width: 95%;
}

.btn-close-import-instructions-modal {
  position: absolute !important;
  top: 4px;
  right: 15px;
  font-size: 1.3em !important;
  cursor: pointer;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  background-color: transparent;
}

.btn-close-import-instructions-modal:hover {
  color: var(--default-hover-color) !important;
  position: absolute;
  background-color: transparent !important;
}

.div-excel-img {
  display: block;
  height: 380px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/excel.png");
}

.span-rule-text {
  font-size: .8em;
}

.td-fade-out-first {
  -webkit-mask: linear-gradient(90deg, #000, #0009);
  mask: linear-gradient(90deg, #000, #0009);
}

.td-fade-out-second {
  -webkit-mask: linear-gradient(90deg, #0009, #0007);
  mask: linear-gradient(90deg, #0009, #0007);
}

.td-fade-out-third {
  -webkit-mask: linear-gradient(90deg, #0006, #0004);
  mask: linear-gradient(90deg, #0006, #0004);
}

.td-fade-out-fourth {
  -webkit-mask: linear-gradient(90deg, #0003, #0001);
  mask: linear-gradient(90deg, #0003, #0001);
}

.i-arrow {
  margin-left: 5px;
  font-size: 1em;
}

.div-last-import-instructions-opened {
  margin-bottom: 250px !important;
}
</style>
